import React, { Component } from 'react';
import { Carousel } from '../components/Carousel';
import './Timeline.scss';

export class Timeline extends Component {

  state = { slick: null, activeIndex: 0 };

  componentDidMount() {
    const checkExist = setInterval(() => {
      const slides = document.querySelectorAll('.timeline-block .slick-slider .slick-slide');
      if (slides.length > 0) {
        this.setSlidesHeight();
        this.setHideContent();
        clearInterval(checkExist);
      }
    }, 100);
    // setTimeout(() => {
    //   this.setSlidesHeight();
    // }, 400);
    // setTimeout(() => {
    //   this.hideClonesText();
    // }, 800);
  }

  setSlidesHeight = () => {
    const slides = document.querySelectorAll('.timeline-block .slick-slider .slick-slide');
    const slidesArray = Array.from(slides);
    const slideHeights = slidesArray.map(slide => slide.clientHeight);
    const maxHeight = slideHeights.reduce((acc, next) => {
      return next > acc ? next : acc;
    }, 0);
    slidesArray.forEach(slide => {
      slide.style.height = `${maxHeight}px`;
    });
  }

  setHideContent = () => {
    const slides = document.querySelectorAll('.timeline-block .slick-slider .slick-slide > div');
    const slidesArray = Array.from(slides);
    slidesArray.forEach(slide => slide.classList.add('hide-content'));
  }

  // hideClonesText = () => {
  //   const clonedSlides = document.querySelectorAll('.timeline-block .slick-slider .slick-slide.slick-cloned');
  //   const clonesArray = Array.from(clonedSlides);
  //   clonesArray.forEach(clone => {
  //     const text = clone.querySelector('.timeline-item-content > p');
  //     const heading = clone.querySelector('.timeline-item-content > h3');
  //     text.style.display = 'none';
  //     heading.style.marginBottom = '0';
  //   });
  // }

  beforeChange = (oldIndex, activeIndex) => {
    this.setState({ activeIndex });
  }

  goToSlide = (event, activeIndex) => {
    const { slick } = this.state;
    if (slick && activeIndex !== null) {
      if (activeIndex < slick.innerSlider.state.currentSlide) {
        slick.slickGoTo(slick.innerSlider.state.slideCount + activeIndex);
      }
      slick.slickGoTo(activeIndex);
    }
  }

  getSlick = (slick) => {
    const { slick: stateSlickObj } = this.state;
    if (!stateSlickObj && slick) {
      this.setState({ slick });
    }
  }

  render() {
    const {
      title,
      bodyText,
      timelineItems,
    } = this.props;
    const { activeIndex } = this.state;
    return (
      <section className="timeline-block">
        <div className="wrapper">
          <h2 dangerouslySetInnerHTML={{__html: title}} />
          {bodyText && <div className="body-text" dangerouslySetInnerHTML={{__html: bodyText}} />}
          <Carousel
            className="timeline-items"
            slidesToShow={1}
            variableWidth
            arrows={false}
            getSlick={this.getSlick}
            beforeChange={this.beforeChange}
          >
            {timelineItems.map((timelineItem, index) => {
              const { logo, label: itemLabel, title: itemTitle, description } = timelineItem;
              return (
                // eslint-disable-next-line
                <div
                  className="timeline-item"
                  onClick={event => this.goToSlide(event, index)}
                  key={`${itemTitle}--${index}`}
                >
                  <div className="timeline-item-content">
                    {timelineItem.logo && timelineItem.logo.url && (
                      <img className="logo" src={logo.url.source_url} alt="Timeline item logo" />
                    )}
                    <h3 dangerouslySetInnerHTML={{__html: itemTitle}} />
                    <p dangerouslySetInnerHTML={{__html: description}} />
                  </div>
                  <span className="timeline-item-label" dangerouslySetInnerHTML={{__html: itemLabel}} />
                </div>
              );
            })}
          </Carousel>
        </div>
      </section>
    );
  }
}
