import React from 'react';
import { graphql } from 'gatsby';
import Previewable from '../components/Previewable';
import Layout from '../components/Layout';
import * as AcfLayout from '../acf';
import SEO from '../components/SEO';
import { decodeEntities } from '../utils/htmlParse';

const AcfComponent = (props) => {
  const {
    location,
    componentName,
    item,
    wordpressUrl,
    showcaseSlug,
    blogSlug,
    relatedProjects,
    featuredProject,
    projectsSliderProjects
  } = props;
  // If component does not exist in acf folder, print error message
  if (!(componentName in AcfLayout)) {
    return (
      <div className="wrapper">
        {`Error: Component does not exist. `}
        {`Please create component "${componentName}.jsx" in src/acf folder `}
        {`and add export to src/acf/index.js`}
      </div>
    );
  }
  const ComponentName = AcfLayout[componentName];
  return (
    <ComponentName
      location={location}
      wordpressUrl={wordpressUrl}
      showcaseSlug={showcaseSlug}
      blogSlug={blogSlug}
      relatedProjects={relatedProjects}
      featuredProject={featuredProject}
      projectsSliderProjects={projectsSliderProjects}
      {...item}
    />
  );
};

const Page = ({ data, location }) => {
  const { wordpressPage: page, site, relatedProjects, featuredProject, projectsSliderProjects, postsFeedPosts } = data;
  if (!page) return null;
  const { title, slug, yoast, acf = {} } = page;
  const { layout } = acf;
  const { title: siteTitle, wordpressUrl, showcaseSlug, blogSlug } = site.siteMetadata;
  return (
    <Layout location={location} className={`page--${slug}`}>
      <SEO
        title={`${
          yoast.metaTitle ?
          decodeEntities(yoast.metaTitle) :
          `${decodeEntities(title)} | ${decodeEntities(siteTitle)}`
        }`}
        desc={yoast.metaDescription}
      />
      {layout && layout.map((item, index) => {
        if (!item.__typename) return null;
        const layoutComponentName = item.__typename.replace('WordPressAcf_','');
        return (
          <AcfComponent
            key={`${item.id}-${index}`}
            componentName={layoutComponentName}
            item={item}
            wordpressUrl={wordpressUrl}
            showcaseSlug={showcaseSlug}
            blogSlug={blogSlug}
            relatedProjects={relatedProjects}
            featuredProject={featuredProject}
            projectsSliderProjects={projectsSliderProjects}
            location={location}
          />
        );
      })}
    </Layout>
  )
}

export default Previewable(Page)

export const pageQuery = graphql`
  query PageById(
    $id: String!,
    $relatedProjectsId: Int,
    $featuredProjectId: Int,
    $projectsSliderProjectIds: [Int],
  ) {
    site {
      siteMetadata {
        title
        wordpressUrl
        showcaseSlug
        blogSlug
        functionsUrl
      }
    }
    wordpressPage(id: { eq: $id }) {
      title
      slug
      content
      yoast {
        metaTitle: title
        metaDescription: metadesc
      }
      acf {
        layout: layout_page {
          __typename
          ... on WordPressAcf_Banner {
            id
            title
            image {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 2048, quality: 90) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            breadcrumbs {
              pageLink
              crumbText
            }
            currentPageCrumbText
          }
          ... on WordPressAcf_PlainText {
            id
            content
          }
          ... on WordPressAcf_BannerSlider {
            id
            slides {
              titleFirstLine
              titleSecondLine
              image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 2048, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
          ... on WordPressAcf_FeaturedProject {
            id
            projectId
          }
          ... on WordPressAcf_Intro {
            id
            title
            useH1
            columnOneText
            columnTwoText
          }
          ... on WordPressAcf_TwoTileInfo {
            id
            title
            subtitle
            firstTileBlueBorders
            firstTileImage {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1200) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            firstTileTitle
            firstTileBody
            firstTileHasButton
            firstTileButtonLink
            firstTileButtonText
            secondTileBlueBorders
            secondTileImage {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1200) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            secondTileTitle
            secondTileBody
            secondTileHasButton
            secondTileButtonLink
            secondTileButtonText
          }
          ... on WordPressAcf_ServicesSlider {
            id
            columnOneText
            columnTwoText
          }
          ... on WordPressAcf_ProjectsSlider {
            id
            title
            bodyText
            hasButton
          }
          ... on WordPressAcf_CtaBlock {
            id
            itemCount
            singleItemLayout
            firstItemImage {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1200) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            firstItemTitle
            firstItemText
            firstItemHasButton
            firstItemButtonLabel
            firstItemButtonLink
            secondItemImage {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1200) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            secondItemTitle
            secondItemText
            secondItemHasButton
            secondItemButtonLabel
            secondItemButtonLink
            thirdItemImage {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1200) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            thirdItemTitle
            thirdItemText
            thirdItemHasButton
            thirdItemButtonLabel
            thirdItemButtonLink
          }
          ... on WordPressAcf_Values {
            id
            firstColumnTitle
            firstColumnItems {
              icon
              title
              text
            }
            secondColumnTitle
            secondColumnItems {
              icon
              title
              text
            }
          }
          ... on WordPressAcf_Timeline {
            id
            title
            bodyText
            timelineItems {
              logo {
                url {
                  source_url
                }
              }
              label
              title
              description
            }
          }
          ... on WordPressAcf_ImageBlock {
            id
            imageBlockImage {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1200) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
          ... on WordPressAcf_ThreeColumnText {
            id
            columns {
              columnTitle
              columnText
            }
          }
          ... on WordPressAcf_RelatedProjects {
            id
            title
            projectType
          }
          ... on WordPressAcf_Form {
            id
            title
            form
          }
          ... on WordPressAcf_Map {
            id
            image {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1200) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
          ... on WordPressAcf_TeamMembers {
            id
            title
            teamMembers {
              photo {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1200) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              name
              role
              bio
            }
          }
        }
      }
    }
    relatedProjects: allWordpressWpProjects(filter: { projecttype: { in: [$relatedProjectsId] } }, limit: 3) {
      edges {
        node {
          title
          slug
          content
          excerpt
          featuredImage: featured_media {
            localFile {
              childImageSharp {
                fluid(maxWidth: 1200) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
    featuredProject: wordpressWpProjects(wordpress_id: { eq: $featuredProjectId }) {
      title
      slug
      acf {
        projectLocation
        designers
        size
        completionDate
        duration
      }
      featuredImage: featured_media {
        localFile {
          childImageSharp {
            fluid(maxWidth: 1200) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
    projectsSliderProjects: allWordpressWpProjects(filter: { wordpress_id: { in: $projectsSliderProjectIds }, slug: { ne: "build-project" } }, limit: 5) {
      edges {
        node {
          title
          slug
          image: featured_media {
            localFile {
              childImageSharp {
                fluid(maxWidth: 1200) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          acf {
            projectLocation
            designers
            size
            completionDate
            duration
          }
        }
      }
    }
  }
`
