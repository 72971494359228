import React from 'react';
import Image from '../components/Image';
import './ImageBlock.scss';

export const ImageBlock = (props) => {
  const { imageBlockImage: image } = props;
  return (
    <section className="image-block">
      <div className="wrapper">
        <Image className="image" image={image} />
      </div>
    </section>
  );
}
