import React from 'react';
import { Link } from 'gatsby';
import OnVisible from 'react-on-visible';
import { getPageSlugFromWpLink } from '../utils/helpers';
import Image from '../components/Image';
import './TwoTileInfo.scss';

export const TwoTileInfo = (props) => {
  const {
    title,
    subtitle,
    firstTileBlueBorders,
    firstTileImage,
    firstTileTitle,
    firstTileBody,
    firstTileHasButton,
    firstTileButtonLink,
    firstTileButtonText,
    secondTileBlueBorders,
    secondTileImage,
    secondTileTitle,
    secondTileBody,
    secondTileHasButton,
    secondTileButtonLink,
    secondTileButtonText,
    wordpressUrl
  } = props;
  return (
    <OnVisible wrappingElement="section" percent={70} className="two-tile-info">
      <div className="wrapper">
        <h2 dangerouslySetInnerHTML={{__html: title}} />
        <span className="subtitle" dangerouslySetInnerHTML={{__html: subtitle}} />
        <div className="tile-wrapper">
          <div className={`tile-1 ${firstTileBlueBorders ? 'saf' : ''}`}>
            <Image className="image" image={firstTileImage} />
            <div className="text-wrapper">
              <h3 dangerouslySetInnerHTML={{__html: firstTileTitle}} />
              <p dangerouslySetInnerHTML={{__html: firstTileBody}} />
              {firstTileHasButton && (
                <Link className="styled-button" to={getPageSlugFromWpLink(firstTileButtonLink, wordpressUrl)}>
                  {firstTileButtonText || `Learn more`}
                </Link>
              )}
            </div>
          </div>
          <div className={`tile-2 ${secondTileBlueBorders ? 'saf' : ''}`}>
            <Image className="image" image={secondTileImage} />
            <div className="text-wrapper">
              <h3 dangerouslySetInnerHTML={{__html: secondTileTitle}} />
              <p dangerouslySetInnerHTML={{__html: secondTileBody}} />
              {secondTileHasButton && (
                <Link className="styled-button" to={getPageSlugFromWpLink(secondTileButtonLink, wordpressUrl)}>
                  {secondTileButtonText || `Learn more`}
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>
    </OnVisible>
  );
}
