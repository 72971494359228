import React, { Component } from 'react';
import { StaticQuery, graphql, Link } from 'gatsby';
import { getPageSlugFromWpLink } from '../utils/helpers';
import { Carousel } from '../components/Carousel';
import Image from '../components/Image';
import './ServicesSlider.scss';

export class ServicesSlider extends Component {
  state = { slick: null, activeIndex: 0 };

  componentDidMount() {
    const checkExist = setInterval(() => {
      const slides = document.querySelectorAll('.services-slider .slick-slider .slick-slide');
      if (slides.length > 0) {
        this.setSlidesHeight();
        this.setHideContent();
        clearInterval(checkExist);
      }
    }, 100);
  }

  setSlidesHeight = () => {
    const slides = document.querySelectorAll('.services-slider .slick-slider .slick-slide');
    const slidesArray = Array.from(slides);
    const slideHeights = slidesArray.map(slide => slide.clientHeight);
    const maxHeight = slideHeights.reduce((acc, next) => {
      return next > acc ? next : acc;
    }, 0);
    slidesArray.forEach(slide => {
      slide.style.height = `${maxHeight}px`;
    });
  }

  setHideContent = () => {
    const slides = document.querySelectorAll('.services-slider .slick-slider .slick-slide > div');
    const slidesArray = Array.from(slides);
    slidesArray.forEach(slide => slide.classList.add('hide-content'));
  }

  beforeChange = (oldIndex, activeIndex) => {
    this.setState({ activeIndex });
  }

  goToSlide = (event, activeIndex) => {
    const { slick } = this.state;
    if (slick && activeIndex !== null) {
      if (activeIndex < slick.innerSlider.state.currentSlide) {
        slick.slickGoTo(slick.innerSlider.state.slideCount + activeIndex);
      }
      slick.slickGoTo(activeIndex);
    }
  }

  getSlick = (slick) => {
    const { slick: stateSlickObj } = this.state;
    if (!stateSlickObj && slick) {
      this.setState({ slick });
    }
  }

  render() {
    return (
      <StaticQuery
        query={graphql`
          query {
            site {
              siteMetadata {
                wordpressUrl
              }
            }
            allServices: allWordpressPage(filter: { tags: { elemMatch: { name: { eq: "service" }}}}) {
              edges {
                node {
                  title
                  banner: childWordPressAcfBanner {
                    image {
                      localFile {
                        childImageSharp {
                          fluid(maxWidth: 1200, quality: 90) {
                            ...GatsbyImageSharpFluid_withWebp
                          }
                        }
                      }
                    }
                  }
                  pageLink: link
                  acf {
                    sliderTitle
                    summary
                  }
                }
              }
            }
          }
        `}
        render={data => {
          const { allServices, site } = data;
          const { siteMetadata } = site;
          const { wordpressUrl } = siteMetadata;
          const { activeIndex } = this.state;
          const { columnOneText, columnTwoText } = this.props;
          return (
            <section className="services-slider">
              <div className="wrapper">
                <div className="services-slider-intro">
                  <h2>Our Sectors</h2>
                  <div className="col-1" dangerouslySetInnerHTML={{__html: columnOneText}} />
                  <div className="col-2" dangerouslySetInnerHTML={{__html: columnTwoText}} />
                </div>
                <Carousel
                  slidesToShow={1}
                  variableWidth
                  arrows={false}
                  getSlick={this.getSlick}
                  beforeChange={this.beforeChange}
                >
                  {allServices.edges.map(({ node: service }, index) => {
                    const isActive = activeIndex === index;
                    return (
                      // eslint-disable-next-line
                      <div
                        className={`service ${isActive ? 'active' : ''}`}
                        key={`${service.title}-${index}`}
                        onClick={event => this.goToSlide(event, index)}
                      >
                        <div className="service-text">
                          <h3 dangerouslySetInnerHTML={{__html: service.acf ? (service.acf.sliderTitle || service.title) : service.title }} />
                          <div className="summary" dangerouslySetInnerHTML={{__html: service.acf.summary}} />
                          <Link className="styled-button" to={getPageSlugFromWpLink(service.pageLink, wordpressUrl)}>Learn more</Link>
                        </div>
                        <Image className="image" image={service.banner.image} />
                      </div>
                    );
                  })}
                </Carousel>
              </div>
            </section>
          )
        }}
      />
    );
  }
}
