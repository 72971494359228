import React, { Component } from 'react';
import * as Forms from '../components/Forms';
import './Form.scss';

export class Form extends Component {
  componentDidMount() {
    const { location } = this.props;
    if (location.search === '?success=true') {
      document.body.classList.add('form-submitted');
      setTimeout(() => document.body.classList.remove('form-submitted'), 3000);
    }
  }

  getForm = (form) => {
    const { location, title } = this.props;
    switch(form) {
      case 'contact':
        return <Forms.Contact location={location} title={title} />;
      default:
        return null;
    }
  }

  render() {
    const { form } = this.props;
    return (
      <section className="form-block">
        <div className="wrapper">
          {this.getForm(form)}
        </div>
      </section>
    );
  }
}
