import React, { Component } from 'react';
import Image from '../components/Image';
import Modal from '../components/Modal';
import './TeamMembers.scss';

export class TeamMembers extends Component {
  state = { modalActive: false, activeTeamMember: null };

  showModal = (teamMember) => {
    this.setState({ activeTeamMember: teamMember, modalActive: true });
  }

  closeModal = () => {
    this.setState({ modalActive: false });
  }

  render() {
    const { title, teamMembers } = this.props;
    const { modalActive, activeTeamMember } = this.state;
    return (
      <section className="team-members-block">
        <div className="wrapper">
          <h2 dangerouslySetInnerHTML={{__html: title}} />
          <div className="team-members">
            {teamMembers.map((teamMember, teamMemberIndex) => {
              return (
                <div className="team-member" key={`${teamMember.name}--${teamMember.role}--${teamMemberIndex}`}>
                  <Image className="photo" image={teamMember.photo} />
                  <div className="team-member-body">
                    <span className="role" dangerouslySetInnerHTML={{__html: teamMember.role}} />
                    <h3 dangerouslySetInnerHTML={{__html: teamMember.name}} />
                    {teamMember.bio && (
                      <>
                        <button className="styled-button" type="button" onClick={() => this.showModal(teamMember)}>View bio</button>
                      </>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        {activeTeamMember && (
          <Modal className="team-member" active={modalActive} closeModal={this.closeModal}>
            <div className="team-member">
              <Image className="photo" image={activeTeamMember.photo} />
              <div className="team-member-body">
                <span className="role" dangerouslySetInnerHTML={{__html: activeTeamMember.role}} />
                <h3 dangerouslySetInnerHTML={{__html: activeTeamMember.name}} />
                <div className="bio" dangerouslySetInnerHTML={{__html: activeTeamMember.bio}} />
              </div>
            </div>
          </Modal>
        )}
      </section>
    );
  }
}
