import React from 'react';
import OnVisible from 'react-on-visible';
import './ThreeColumnText.scss';

export const ThreeColumnText = (props) => {
  const {
    columns
  } = props;
  return (
    <OnVisible wrappingElement="section" percent={30} className="three-column-text">
      <div className="wrapper">
        <div className="columns">
          {columns.map((column, columnIndex) => (
            <div className="column" key={`${column.columnTitle}--${columnIndex}`}>
              <h2 dangerouslySetInnerHTML={{__html: column.columnTitle}} />
              <div className="column-text" dangerouslySetInnerHTML={{__html: column.columnText}} />
            </div>
          ))}
        </div>
      </div>
    </OnVisible>
  );
}
