import React from 'react';
import OnVisible from 'react-on-visible';
import respectSvg from '../images/icons/respect.svg';
import innovateSvg from '../images/icons/innovate.svg';
import puzzleSvg from '../images/icons/puzzle.svg';
import qualitySvg from '../images/icons/quality.svg';
import searchSvg from '../images/icons/search.svg';
import toolsSvg from '../images/icons/tools.svg';
import './Values.scss';

const getValueIconUrl = (icon) => {
  switch(icon) {
    case 'respect':
      return respectSvg;
    case 'innovate':
      return innovateSvg;
    case 'puzzle':
      return puzzleSvg;
    case 'quality':
      return qualitySvg;
    case 'search':
      return searchSvg;
    case 'tools':
      return toolsSvg;
    default:
      return null;
  }
}

export const Values = (props) => {
  const {
    firstColumnTitle,
    firstColumnItems,
    secondColumnTitle,
    secondColumnItems,
  } = props;
  return (
    <OnVisible wrappingElement="section" percent={60} className="values-block">
      <div className="wrapper">
        <div className="col-1">
          <h2 dangerouslySetInnerHTML={{__html: firstColumnTitle}} />
          <div className="value-items">
            {firstColumnItems.map((item, colOneItemIndex) => (
              <div className="value-item" key={`${item.title}--${colOneItemIndex}`}>
                <img className={`value-icon ${item.icon}`} src={getValueIconUrl(item.icon)} alt="Value Icon" />
                <div className="value-text-wrapper">
                  <h3 dangerouslySetInnerHTML={{__html: item.title}} />
                  <div className="value-text" dangerouslySetInnerHTML={{__html: item.text}} />
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="col-2">
          <h2 dangerouslySetInnerHTML={{__html: secondColumnTitle}} />
          <div className="value-items">
            {secondColumnItems.map((item, colTwoItemIndex) => (
              <div className="value-item" key={`${item.title}--${colTwoItemIndex}`}>
                <img className={`value-icon ${item.icon}`} src={getValueIconUrl(item.icon)} alt="Value Icon" />
                <div className="value-text-wrapper">
                  <h3 dangerouslySetInnerHTML={{__html: item.title}} />
                  <div className="value-text" dangerouslySetInnerHTML={{__html: item.text}} />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </OnVisible>
  );
}
