import React, { useState } from 'react';
import axios from 'axios';
import './FormWrapper.scss';

const FormWrapper = ({ children, className, url, location }) => {
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(false);
  const onSubmit = async (e) => {
    e.preventDefault()
    setLoading(true);
    const formData = {};
    Array.prototype.forEach.call(e.target.elements, field => {
      if (field.type !== 'submit') {
        formData[field.name] = field.value;
      }
    });
    await axios({
      method: 'POST',
      // Formbucket post url here
      url,
      data: formData
    })
    .then((response) => {
      // Handle success
      setLoading(false);
      setSubmitted(true);
    })
    .catch((response) => {
      // Handle error
      setLoading(false);
      setSubmitted(false);
      setError(true);
    });
  }
  return (
    <div className={`form-wrapper ${className}`}>
      {loading ? (
        <div className="loading">
          <span>Loading...</span>
        </div>
      ) : (
        <>
          {submitted ? (
            <div className="confirmation">
              {!error ? (
                <p>Thanks for contacting us! We will get in touch with you shortly.</p>
              ) : (
                <p>An error occurred while submitting your form.</p>
              )}
            </div>
          ) : (
            <form onSubmit={onSubmit}>
              {children}
            </form>
          )}
        </>
      )}
    </div>
  );
};

export default FormWrapper;
