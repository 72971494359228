import React from 'react';
import { Carousel } from '../components/Carousel';
import Image from '../components/Image';
import './BannerSlider.scss';

export const BannerSlider = (props) => {
  const {
    slides
  } = props;
  return (
    <section className="banner-slider">
      <Carousel
        arrows={false}
        autoplay
        autoplaySpeed={5000}
        pauseOnHover={false}
        infinite
      >
        {slides.map((slide, index) => {
          const { image, titleFirstLine, titleSecondLine } = slide;
          return (
            <div className="banner-slide" key={`${titleFirstLine}-${index}`}>
              <Image className="image" image={image} />
              <div className="wrapper">
                <div className="banner-slide-content">
                  {titleFirstLine}
                  <span>{titleSecondLine}</span>
                </div>
              </div>
            </div>
          );
        })}
      </Carousel>
    </section>
  );
}
