import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import './Modal.scss';

const isClient = typeof window !== 'undefined';

const appRoot = isClient ? document.getElementById('___gatsby') : null;

export default class Modal extends Component {
  state = { scrollTop: 0 }

  componentDidMount() {
    const { active } = this.props;
    if (active) {
      this.lockBody();
    } else {
      this.unlockBody();
    }
    if (isClient) {
      document.addEventListener('keydown', this.handleEscKey, false);
      document.addEventListener('beforeunload', this.onUnload);
    }
  }

  componentWillReceiveProps(nextProps) {
    const { active } = this.props;
    if (!active) {
      this.lockBody();
    } else {
      this.unlockBody();
    }
    if (active && !nextProps.active) {
      this.onUnload();
    }
  }

  componentWillUnmount() {
    if (isClient) {
      document.removeEventListener('keydown', this.handleEscKey, false);
      window.removeEventListener('beforeunload', this.onUnload);
    }
  }

  onUnload = (event) => {
    const { warnBeforeClose } = this.props;
    const promptConfirm = document.querySelector('.loading') || warnBeforeClose;
    // eslint-disable-next-line
    if (promptConfirm && event) event.returnValue = 'Are you sure?';
  }

  handleEscKey = (event) => {
    const { closeModal } = this.props;
    if (event.keyCode === 27) closeModal(event);
  }

  handleModalBackgroundClick = (event) => {
    const { closeModal } = this.props;
    if (event.target.className.indexOf('modal ') !== -1) {
      closeModal(event);
    }
  }

  lockBody = () => {
    const scrollTop = window.scrollY || document.body.scrollTop || 0;
    this.setState({ scrollTop }, () => {
      document.body.classList.add('nav-open');
      document.body.style.top = `-${scrollTop}px`;
    });
  };

  unlockBody = () => {
    const { scrollTop } = this.state;
    document.body.classList.remove('nav-open');
    document.body.style.top = '0px';
    window.scrollTo(0, scrollTop);
    this.setState({ scrollTop: 0 });
  };

  render() {
    const {
      active,
      closeModal,
      title,
      className,
      children
    } = this.props;
    if (!active) return null;
    return ReactDOM.createPortal(
      // eslint-disable-next-line
      <div
        className={`modal ${className || ''}`}
        onClick={event => this.handleModalBackgroundClick(event)}
        role="dialog"
      >
        <div className="modal-content">
          <div className="modal-content-body">
            <button className="close-button" type="button" onClick={event => closeModal(event)} aria-label={title} />
            {children}
          </div>
        </div>
      </div>,
      appRoot
    );
  }
}
