import React from 'react';
import { Link } from 'gatsby';
import OnVisible from 'react-on-visible';
import Image from '../components/Image';
import { getPageSlugFromWpLink } from '../utils/helpers';
import './CtaBlock.scss';

const CtaItem = ({ image, title, text, hasButton, buttonLabel, buttonLink, wordpressUrl }) => {
  return (
    <div className="cta-item">
      <Image className="cta-image" image={image} />
      <div className="cta-body">
        <h2 dangerouslySetInnerHTML={{__html: title}} />
        <div className="body-text" dangerouslySetInnerHTML={{__html: text}} />
        {hasButton && (
          <Link className="styled-button" to={getPageSlugFromWpLink(buttonLink, wordpressUrl)}>{buttonLabel || `Learn more`}</Link>
        )}
      </div>
    </div>
  );
}

export const CtaBlock = (props) => {
  const {
    wordpressUrl,
    itemCount,
    singleItemLayout,
    firstItemImage,
    firstItemTitle,
    firstItemText,
    firstItemHasButton,
    firstItemButtonLabel,
    firstItemButtonLink,
    secondItemImage,
    secondItemTitle,
    secondItemText,
    secondItemHasButton,
    secondItemButtonLabel,
    secondItemButtonLink,
    thirdItemImage,
    thirdItemTitle,
    thirdItemText,
    thirdItemHasButton,
    thirdItemButtonLabel,
    thirdItemButtonLink,
  } = props;
  let className = 'cta-block ';
  switch(itemCount) {
    case 'one':
      className += 'single-item ';
      break;
    case 'two':
      className += 'two-col ';
      break;
    case 'three':
      className += 'three-col ';
      break;
    default:
      break;
  }
  const showSecondItem = itemCount === 'two' || itemCount === 'three';
  const showThirdItem = itemCount === 'three';
  return (
    <OnVisible wrappingElement="section" percent={60} className={`${className}${itemCount === 'one' ? singleItemLayout : ''}`}>
      <div className="wrapper">
        <CtaItem
          image={firstItemImage}
          title={firstItemTitle}
          text={firstItemText}
          hasButton={firstItemHasButton}
          buttonLabel={firstItemButtonLabel}
          buttonLink={firstItemButtonLink}
          wordpressUrl={wordpressUrl}
        />
        {showSecondItem && (
          <CtaItem
            image={secondItemImage}
            title={secondItemTitle}
            text={secondItemText}
            hasButton={secondItemHasButton}
            buttonLabel={secondItemButtonLabel}
            buttonLink={secondItemButtonLink}
            wordpressUrl={wordpressUrl}
          />
        )}
        {showThirdItem && (
          <CtaItem
            image={thirdItemImage}
            title={thirdItemTitle}
            text={thirdItemText}
            hasButton={thirdItemHasButton}
            buttonLabel={thirdItemButtonLabel}
            buttonLink={thirdItemButtonLink}
            wordpressUrl={wordpressUrl}
          />
        )}
      </div>
    </OnVisible>
  );
}
