import React from 'react';
import Image from '../components/Image';
import './Map.scss';

export const Map = (props) => {
  const { image } = props;
  return (
    <section className="map">
      <Image className="image" image={image} />
    </section>
  );
}
