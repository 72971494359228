import React from 'react';
import { decodeEntities } from '../utils/htmlParse';
import './Intro.scss';

export const Intro = (props) => {
  const {
    title,
    useH1,
    columnOneText,
    columnTwoText
  } = props;
  const HeaderTag = useH1 ? 'h1' : 'h2';
  const columnOneParagraphCount = columnOneText.split('</p>').slice(0, -1).length;
  return (
    <section className="intro">
      <div className="wrapper">
        <HeaderTag className="intro-heading">
          {decodeEntities(title)}
        </HeaderTag>
        <div className={`col-1 ${columnOneParagraphCount > 1 ? 'first-para-strong' : ''}`} dangerouslySetInnerHTML={{__html: columnOneText}} />
        <div className="col-2" dangerouslySetInnerHTML={{__html: columnTwoText}} />
      </div>
    </section>
  );
}
