import React from 'react';
import './ImageWithContent.scss';

export const ImageWithContent = (props) => {
  // const { title, subtitle } = props;
  return (
    <section className="image-with-content">
      <div className="wrapper">
        ImageWithContent
      </div>
    </section>
  );
}
