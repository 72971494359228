import React, { Component } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import FormWrapper from '../FormWrapper';
import './Contact.scss';

export class Contact extends Component {
  render() {
    const { location, title = 'Get in touch' } = this.props;
    const formSettings = {
      location,
      url: 'https://api.formbucket.com/f/buk_I9oinxGdumX7860Ek0PnIVYL',
      className: "contact-form",
    };
    return (
      <StaticQuery
        query={graphql`
          query {
            siteSettings: wordpressAcfOptions {
              options {
                phoneNumber
                faxNumber
                emailAddress
                address: addressDisplay
              }
            }
          }
        `}
        render={(data) => {
          const {
            siteSettings: {
              options: {
                phoneNumber,
                faxNumber,
                emailAddress,
                address
              }
            }
          } = data;
          return (
            <div className="contact-form-block">
              <div className="contact-form-text">
                <h2 dangerouslySetInnerHTML={{__html: title}} />
                <div className="company-details">
                  {phoneNumber && (
                    <span className="phone">
                      <a href={`tel:${phoneNumber}`} dangerouslySetInnerHTML={{__html: phoneNumber}} />
                    </span>
                  )}
                  {faxNumber && <span className="fax" dangerouslySetInnerHTML={{__html: faxNumber}} />}
                  {emailAddress && (
                    <span className="email">
                      <a href={`mailto:${emailAddress}`} dangerouslySetInnerHTML={{__html: emailAddress}} />
                    </span>
                  )}
                  {address && <span className="address" dangerouslySetInnerHTML={{__html: address}} />}
                </div>
              </div>
              <FormWrapper {...formSettings}>
                <label htmlFor="contact-name">
                  <input
                    type="text"
                    name="Name"
                    id="contact-name"
                    placeholder="Name*"
                    className="name"
                    onChange={this.validateName}
                    required
                  />
                  <span>Name*</span>
                </label>
                <label htmlFor="contact-phone">
                  <input
                    type="text"
                    name="Phone"
                    id="contact-phone"
                    placeholder="Phone*"
                    className="phone"
                    required
                  />
                  <span>Phone*</span>
                </label>
                <label htmlFor="contact-email">
                  <input
                    type="email"
                    id="contact-email"
                    name="Email"
                    placeholder="Email*"
                    className="email"
                    required
                  />
                  <span>Email*</span>
                </label>
                <label htmlFor="contact-company">
                  <input
                    type="text"
                    id="contact-company"
                    name="Company"
                    placeholder="Company"
                    className="company"
                  />
                  <span>Company</span>
                </label>
                <label htmlFor="contact-message">
                  <textarea
                    className="message"
                    id="contact-message"
                    rows="5"
                    name="Message"
                    placeholder="Message"
                  />
                  <span>Message</span>
                </label>
                <button className="styled-button" type="submit" aria-label="Submit">Submit</button>
              </FormWrapper>
            </div>
          );
        }}
      />
    );
  }
}
