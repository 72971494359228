import React from 'react';
import { StaticQuery, graphql, Link } from 'gatsby';
import { decodeEntities } from '../utils/htmlParse';
import OnVisible from 'react-on-visible';
import Image from '../components/Image';
import './FeaturedProject.scss';

export const FeaturedProject = (props) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          latestFeaturedProject: allWordpressWpProjects(filter: { tags: { elemMatch: { slug: { eq: "featured" } } } }, limit: 1) {
            edges {
              node {
                title
                image: featured_media {
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 1200, quality: 90) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                }
                acf {
                  projectLocation
                  designers
                  size
                  completionDate
                  duration
                }
              }
            }
          }
        }
      `}
      render={data => {
        const { featuredProject, showcaseSlug } = props;
        if (!featuredProject) return null;
        const { title, featuredImage, slug: projectSlug, acf } = featuredProject;
        const { projectLocation, designers, size, completionDate, duration } = acf;
        return (
          <OnVisible wrappingElement="section" percent={60} className="featured-project">
            <div className="wrapper">
              <h2>Featured Project</h2>
              <div className="project-container">
                <Image className="image" image={featuredImage} />
                <div className="details-wrapper">
                  <strong dangerouslySetInnerHTML={{__html: title}} />
                  <div className="details">
                    <span>
                      <strong>Location: </strong>
                      {decodeEntities(projectLocation)}
                    </span>
                    {designers && (
                      <span>
                        <strong>Designers: </strong>
                        {decodeEntities(designers)}
                      </span>
                    )}
                    {size && (
                      <span>
                        <strong>Size: </strong>
                        {decodeEntities(size)}
                      </span>
                    )}
                    <Link className="styled-button" to={`/${showcaseSlug}/${projectSlug}/`}>Learn more</Link>
                  </div>
                </div>
              </div>
            </div>
          </OnVisible>
        );
      }}
    />
  );
}
