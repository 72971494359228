import React, { Component } from 'react';
import { Link } from 'gatsby';
import OnVisible from 'react-on-visible'
import { Carousel } from '../components/Carousel';
import { decodeEntities } from '../utils/htmlParse';
import Image from '../components/Image';
import './ProjectsSlider.scss';

export class ProjectsSlider extends Component {
  state = { slick: null, activeIndex: 0 };

  beforeChange = (oldIndex, activeIndex) => {
    this.setState({ activeIndex });
  }

  goToSlide = (event, activeIndex) => {
    const { slick } = this.state;
    if (slick && activeIndex !== null) {
      if (activeIndex < slick.innerSlider.state.currentSlide) {
        slick.slickGoTo(slick.innerSlider.state.slideCount + activeIndex);
      }
      slick.slickGoTo(activeIndex);
    }
  }

  getSlick = (slick) => {
    const { slick: stateSlickObj } = this.state;
    if (!stateSlickObj && slick) {
      this.setState({ slick });
    }
  }

  render() {
    const { activeIndex } = this.state;
    const { title, bodyText, hasButton, projectsSliderProjects, showcaseSlug } = this.props;
    if (!projectsSliderProjects) return null;
    return (
      <OnVisible wrappingElement="section" percent={30} className="projects-slider">
        <div className="wrapper">
          <div className={`projects-slider-intro ${(!bodyText && !hasButton) ? 'no-content' : ''}`}>
            <h2 dangerouslySetInnerHTML={{__html: title || `Our Projects`}} />
            <div className="col-1">
              {bodyText && <div className="text" dangerouslySetInnerHTML={{__html: bodyText}} />}
              {hasButton && <Link className="styled-button" to={`/${showcaseSlug}/`}>View all projects</Link>}
            </div>
            <div className="col-2" />
          </div>
          <div className="projects-wrapper">
            <div className="projects-images">
              <Carousel
                fade
                dots={false}
                arrows={false}
                draggable={false}
                getSlick={this.getSlick}
                beforeChange={this.beforeChange}
              >
                {projectsSliderProjects.edges.map(({node: project}, projectImageIndex) => {
                  const isActive = activeIndex === projectImageIndex;
                  return (
                    <div className={`project-image ${isActive ? 'active' : ''}`} key={`${project.title}-${projectImageIndex}`}>
                      <Image image={project.image} />
                    </div>
                  );
                })}
              </Carousel>
            </div>
            <OnVisible percent={60} className="projects-details">
              {projectsSliderProjects.edges.map(({node: project}, detailsIndex) => {
                const { acf: { projectLocation, designers, size, completionDate, duration } } = project;
                const isActive = activeIndex === detailsIndex;
                return (
                  <div className={`details-wrapper ${isActive ? 'active' : ''}`} key={`${project.title}-${detailsIndex}`}>
                    {/* eslint-disable-next-line */}
                    <strong dangerouslySetInnerHTML={{__html: project.title}} onClick={event => this.goToSlide(event, detailsIndex)} />
                    <Image className="image" image={project.image} />
                    <div className="details">
                      <span>
                        <strong>Location: </strong>
                        {decodeEntities(projectLocation)}
                      </span>
                      {designers && (
                        <span>
                          <strong>Designers: </strong>
                          {decodeEntities(designers)}
                        </span>
                      )}
                      {size && (
                        <span>
                          <strong>Size: </strong>
                          {decodeEntities(size)}
                        </span>
                      )}
                      {completionDate && (
                        <span>
                          <strong>Completion: </strong>
                          {decodeEntities(completionDate)}
                        </span>
                      )}
                      {duration && (
                        <span>
                          <strong>Duration: </strong>
                          {decodeEntities(duration)}
                        </span>
                      )}
                      <Link className="styled-button" to={`/${showcaseSlug}/${project.slug}/`}>Learn more</Link>
                    </div>
                  </div>
                );
              })}
            </OnVisible>
          </div>
        </div>
      </OnVisible>
    );
  }
}
