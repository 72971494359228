import React, { Component } from 'react';
import { StaticQuery, graphql, Link } from 'gatsby';
import Image from '../components/Image';
import './ProjectsFeed.scss';

export class ProjectsFeed extends Component {
  state = {
    // filter state should be a project type ID (Int) or null for all projects
    filter: null
  }

  filterProjects = ({node: project}) => {
    const { filter } = this.state;
    if (filter === null) return true;
    return project.projectTypeIds.indexOf(filter) >= 0;
  }

  onFilterSelect = (e, filter) => {
    e.preventDefault();
    this.setState({ filter });
  }

  render() {
    return (
      <StaticQuery
        query={graphql`
          query {
            allProjects: allWordpressWpProjects(filter: { wordpress_id: { ne: 357 } }) {
              edges {
                node {
                  title
                  slug
                  tags {
                    slug
                  }
                  projectTypeIds: projecttype
                  image: featured_media {
                    localFile {
                      childImageSharp {
                        fluid(maxWidth: 1200, quality: 90) {
                          ...GatsbyImageSharpFluid_withWebp
                        }
                      }
                    }
                  }
                }
              }
            }
            allProjectTypes: allWordpressWpProjecttype(filter: { count: { gt: 0 } }) {
              edges {
                node {
                  wordpress_id
                  name
                  slug
                }
              }
            }
          }
        `}
        render={data => {
          const { showcaseSlug } = this.props;
          const { filter } = this.state;
          const { allProjects, allProjectTypes } = data;
          return (
            <section className="projects-feed">
              <div className="wrapper">
                {allProjectTypes && allProjectTypes.edges && (
                  <div className="filters">
                    <span className="filter-by">Filter by:</span>
                    <button
                      type="button"
                      className={`filter${filter === null ? ' active' : ''}`}
                      onClick={(e) => this.onFilterSelect(e, null)}
                    >
                      <span>All</span>
                    </button>
                    {allProjectTypes.edges.map(({node: projectType}) => (
                      <button
                        type="button"
                        className={`filter${filter === projectType.wordpress_id ? ' active' : ''}`}
                        onClick={(e) => this.onFilterSelect(e, projectType.wordpress_id)}
                        key={projectType.wordpress_id}
                      >
                        <span dangerouslySetInnerHTML={{__html: projectType.name}} />
                      </button>
                    ))}
                  </div>
                )}
                {allProjects ? (
                  <>
                    {allProjects.edges.filter(this.filterProjects).length > 0 ? (
                      <div className="project-items">
                        {allProjects.edges.filter(this.filterProjects).map(({node: project}) => {
                          const { title: projectTitle, image: projectImage, projectTypeIds, slug: projectSlug } = project;
                          const projectType = (projectTypeIds && projectTypeIds.length > 0) ?
                            allProjectTypes.edges.find(({node: typeObj}) => typeObj.wordpress_id === projectTypeIds[0]) :
                            null;
                          const isSaf = project.tags ? project.tags.map(tag => tag.slug).includes('saf') : false;
                          return (
                            <Link
                              className={`project-item ${isSaf ? 'saf' : ''}`}
                              to={`/${showcaseSlug}/${projectSlug}/`}
                              key={projectSlug}
                            >
                              <Image className="project-item-image" image={projectImage} />
                              <div className="project-item-body">
                                {projectType && <span className="project-item-type" dangerouslySetInnerHTML={{__html: projectType.node.name}} />}
                                <h2 dangerouslySetInnerHTML={{__html: projectTitle}} />
                              </div>
                            </Link>
                          );
                        })}
                      </div>
                    ) : (
                      <div className="no-projects-match">
                        {`Sorry, we couldn't find any projects that matched your selected filter.`}
                      </div>
                    )}
                  </>
                ) : (
                  <div className="no-projects">
                    {`No projects found.`}
                  </div>
                )}
              </div>
            </section>
          );
        }}
      />
    );
  }
}
