import React from 'react';
import './PlainText.scss';

export const PlainText = ({ content }) => {
  return (
    <section className="plain-text-block">
      <div className="wrapper">
        <div className="content" dangerouslySetInnerHTML={{__html: content}} />
      </div>
    </section>
  );
}
